import { Injectable } from "@angular/core";
import { RoutingHelpers } from "@app/common/helpers/routing.helpers";
import { MenuItem } from "primeng/api";
import { UserSettingsService } from "../user-settings.service";
import { Breadcrumb } from "./breadcrumb.class";

@Injectable({
    providedIn: "root",
})
export class BreadcrumbsService {
    private breadcrumbs: Breadcrumb[] = [];

    constructor(private userSettingsService: UserSettingsService) {
        this.breadcrumbs = this.getFromStorage();
    }

    public setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
        // if (this.setFromHistoryState()){
        //     return;
        // }

        if (!this.breadcrumbs?.length) {
            this.breadcrumbs = breadcrumbs;
            this.store();
            return;
        }

        let changedBreadcrumbs = breadcrumbs.filter((breadcrumb, index) => {
            return this.breadcrumbs?.[index]?.getLabel() != breadcrumb.getLabel();
        });
        // console.log(changedBreadcrumbs);
        if (changedBreadcrumbs?.length) {
            this.breadcrumbs = breadcrumbs;
            this.store();
        }
    }

    public getBreadcrumbs(currentLabel: string = null): MenuItem[] {
        if (!currentLabel) {
            // console.log(this.userSettingsService.activeMenu);
            currentLabel = this.userSettingsService?.activeMenu?.name || "";
        }

        this.modifyBasedOnCurrent(currentLabel);
        return this.getAsMenuItems();
    }

    private modifyBasedOnCurrent(currentLabel: string): void {
        let indexOfCurrentBreadcrumb = this.breadcrumbs.findIndex((item) => item.getLabel() == currentLabel);
        if (indexOfCurrentBreadcrumb != -1) {
            //breadcrumb already in the list
            this.removeEverythingAfter(indexOfCurrentBreadcrumb + 1);
        } else {
            //new breadcrumb
            this.add(new Breadcrumb(currentLabel, window.location.pathname));
        }
        this.store();
    }

    private removeEverythingAfter(index: number) {
        this.breadcrumbs.splice(index);
    }

    private add(breadcrumb: Breadcrumb) {
        this.breadcrumbs.push(breadcrumb);
    }

    private store() {
        sessionStorage.setItem("breadcrumbs", JSON.stringify(this.breadcrumbs));
    }

    private getAsMenuItems(): MenuItem[] {
        // let currentBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
        // console.log(currentBreadcrumb.getLabel());
        // console.log(currentBreadcrumb.getModuleBaseUrl());
        let currentBreadcrumbBaseUrl = this.breadcrumbs[this.breadcrumbs.length - 1].getModuleBaseUrl();
        let menuItems: MenuItem[] = [];
        this.breadcrumbs.forEach((breadcrumb) => {
            let menuItem: MenuItem = { label: breadcrumb.getLabel() };
            if (breadcrumb.getModuleBaseUrl() == currentBreadcrumbBaseUrl) {
                menuItem.routerLink = RoutingHelpers.urlToRouterLink(breadcrumb.getUrl());
            } else {
                menuItem.url = breadcrumb.getUrl();
                menuItem.target = "_self";
            }
            menuItems.push(menuItem);
        });
        // console.log(menuItems);
        return menuItems;
    }

    private getFromStorage(): Breadcrumb[] {
        if (sessionStorage.getItem("breadcrumbs")) {
            let breadcrumbs: any[] = JSON.parse(sessionStorage.getItem("breadcrumbs"));
            return breadcrumbs.map(item => new Breadcrumb(item.label, item.url));
        }
        return [];
    }

    // private setFromHistoryState(): boolean {
    //     console.log(history.state?.breadcrumbs);
    //     if (history?.state?.breadcrumbs) {
    //         this.breadcrumbs = history.state.breadcrumbs;
    //         return true;
    //     }
    //     return false;
    // }
}
